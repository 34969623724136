import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const Layout = React.lazy(() => import('./containers/layout'));

// Pages
// May include different actual pages outside of the SPA

function App () {

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/" name="Home" render={ props => <Layout {...props}/>} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App;
